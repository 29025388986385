import type * as React from 'react';
import cx from 'classnames';
import { useTranslation } from 'next-i18next';

type IPreviewHeadline = {
  title: string;
  titleType?: 'heading' | 'subheading';
  showTitle?: boolean;
  dates?: {
    arrivalDateFmt: string;
    departureDateFmt: string;
    cutOffDateFmt: string;
  };
};

const PreviewHeadline: React.FC<React.PropsWithChildren<IPreviewHeadline>> = ({
  title,
  showTitle = true,
  titleType = 'heading',
  dates,
}) => {
  const { t } = useTranslation();
  const isSubHeading = titleType === 'subheading';
  return (
    <div
      className={cx('my-5 md:mx-0', {
        'mx-0': isSubHeading,
      })}
    >
      <div
        className={cx({
          'py-2 px-0': !isSubHeading,
        })}
      >
        {showTitle && (
          <div>
            {titleType === 'subheading' ? (
              <h2 className="font-headline mb-0 flex text-2xl font-black md:text-3xl">{title}</h2>
            ) : (
              <h1 className="font-headline mb-0 flex text-3xl font-black md:text-4xl">{title}</h1>
            )}
          </div>
        )}
        {dates && (
          <div className="mb-2">
            <div className="mx-0">
              <h3 className="mb-0 inline-block text-lg font-bold">
                <span className="mr-2 inline-block">{t('textContent.eventDates')}</span>
                <span className="mr-2 inline-block font-normal">{`${dates.arrivalDateFmt} - ${dates.departureDateFmt}`}</span>
              </h3>
            </div>
            {dates.cutOffDateFmt && (
              <div>
                <h3 className="mb-0 inline-block text-base font-bold">
                  <span className="mr-2 inline-block">{t('textContent.bookBy')}</span>
                  <span className="mr-2 inline-block font-normal">{dates.cutOffDateFmt}</span>
                </h3>
              </div>
            )}
          </div>
        )}
      </div>
      <div className="horizontal-bar relative h-[3px]" data-testid="progressStatusBar" />
    </div>
  );
};

export default PreviewHeadline;
