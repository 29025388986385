import type * as React from 'react';
import { useTranslation, Trans } from 'next-i18next';
import type { GetShopAvailQuery } from '../../generated/types';
import { InfoPopup } from '@dx-ui/osc-info-popup';
import Markdown from 'markdown-to-jsx';

type IProps = {
  roomRates?: NonNullable<NonNullable<GetShopAvailQuery['hotel']>['shopAvail']>['roomRates'];
  isNoAvailError: boolean;
  isAvailBellCurveError?: boolean;
  bookURL?: string;
};

const RoomsRates: React.FC<React.PropsWithChildren<IProps>> = ({
  isNoAvailError,
  roomRates,
  bookURL,
  isAvailBellCurveError,
}) => {
  const { t } = useTranslation();
  const visitBookingPageLink = (
    <a href={bookURL} data-testid="resrvationURL" className="text-primary font-medium">
      {t('buttonsAndLinks.visitBookingPage')}
    </a>
  );
  const serviceChargeDetails = roomRates?.find(
    (roomRate) => !!roomRate.serviceChargeDetails
  )?.serviceChargeDetails;

  return (
    <div className="mt-8 flex flex-1 flex-col md:mx-11 md:mt-0">
      <div className="flex items-center">
        <h3 className="text-xl font-bold leading-none">{t('heading.roomsNRates')}</h3>
        <InfoPopup label={t('heading.roomsNRatesInfoLabel')}>
          <span className="flex w-64">{t('heading.roomsNRatesInformation')}</span>
        </InfoPopup>
      </div>
      {serviceChargeDetails && !isNoAvailError && roomRates.length > 0 ? (
        <div className="pt-1.5 text-sm">
          <Markdown>{serviceChargeDetails}</Markdown>
        </div>
      ) : null}
      {roomRates && !isNoAvailError && roomRates.length === 0 && (
        <>
          <span className="py-1 text-xs leading-tight" aria-hidden="true">
            {t('textContent.roomsNRatesInfo')}
          </span>
          <ul className="m-0 mt-4 list-none p-0" aria-hidden="true">
            <li className="bg-bg-alt mb-6 h-6" />
            <li className="bg-bg-alt mb-6 h-6" />
            <li className="bg-bg-alt mb-6 h-6" />
            <li className="bg-bg-alt m-0 h-6" />
          </ul>
        </>
      )}
      {roomRates && !isNoAvailError && roomRates.length > 0 && (
        <ul className="m-0 mt-4 list-none p-0">
          {roomRates.map((room) => (
            <li className="py-1" key={`${room.roomType?.roomTypeName}${room.rateAmountFmt}`}>
              <span>{`${room?.roomType?.roomTypeName || ''} - `}</span>
              <span className="font-bold">{room.rateAmountFmt}</span>
            </li>
          ))}
        </ul>
      )}
      {isNoAvailError && !isAvailBellCurveError && (
        <p className="bg-bg-primary mt-6 px-6 py-4 text-lg leading-normal">
          {t('messages.error.noAvailError')}
          <a href={bookURL} data-testid="resrvationURL" className="text-primary font-medium">
            {t('buttonsAndLinks.clickHere')}
          </a>
          .
        </p>
      )}
      {isAvailBellCurveError && (
        <p
          className="bg-bg-primary mt-6 px-6 py-4 text-lg leading-normal"
          {...{ role: 'alert', 'aria-live': 'polite', 'aria-atomic': 'true' }}
        >
          <Trans i18nKey="messages.info.availBellCurve" components={[visitBookingPageLink]} />
        </p>
      )}
    </div>
  );
};

export default RoomsRates;
