import type * as React from 'react';
import { useTranslation } from 'next-i18next';

const InlineLoader: React.FC<React.PropsWithChildren<unknown>> = () => {
  const { t } = useTranslation();

  return (
    <div className="px-0 py-4 focus:outline-none" role="alert">
      <span className="sr-only">{t('textContent.pleaseWait')}</span>
      <div className="inline-loader relative size-16">
        <div className="active">
          <div className="spinner-layer">
            <div className="circle-clipper left">
              <div className="circle" />
            </div>
            <div className="gap-patch">
              <div className="circle" />
            </div>
            <div className="circle-clipper right">
              <div className="circle" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InlineLoader;
